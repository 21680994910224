.header{
/* border: 2px solid red; */
}
.header-container{
display: flex;
background-color: #EDEDED;
}
.header-text{
    /* border: 2px solid black; */
    flex: 1 1 60rem;
    margin: auto;   
    padding: 5rem 0;    
}
.header-text h1{
    font-size: 4rem;
    margin-bottom: 3rem;
    text-align: center;    
}
.header-image{
    /* border: 2px solid green; */
    flex: 1 1 60rem;
}
.btn-header{
    font-size: 2rem;
    padding: 1rem 1.5rem;
    background-color: #6A4AA1;
    color: white;
    display: block;
    margin: auto;
    border: none;
    outline: none;    
    cursor: pointer;
}
.btn-header:hover{
    background-color:#D01E55;    
    transition: 0.5s;
}
.header-image img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}

/* Media Queries */

@media(max-width:450px){
    .header-container{
      flex-wrap: wrap;
        }
}