.sub{
    /* border: 2px solid red; */
    padding: 10rem 0;
    /* background-image: linear-gradient(-225deg, #A445B2 0%, #D41872 52%, #FF0066 100%); */
    background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%);
    
}
.sub-heading{
    font-size: 5rem;
    text-align: center;
    margin-bottom: 5rem;
    color: black;
}
.sub-container{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    
    /* width: 25%; */
}
.sub-items{
    display: grid;
    place-items: center;
    /* border: 2px solid blue; */
    /* width: 50%; */
    padding: 2rem;
}
.sub-input{
    padding: 2rem;
    margin-bottom: 2rem;
    width: 40rem;  
    text-align: center;  
    font-size: 2rem;
    background-color: #333333;
    color: white;
    border: none;
    outline: none;   

}

.btn-submit{
    font-size: 2rem;
    /* padding: 1rem 1.5rem; */
    padding: 2rem;
    background-color:#292E33;  
    color: white;
    border: none;
    outline: none;    
    cursor: pointer;
    /* margin-bottom: 3rem; */
    width: 40rem;
    
    
}
.btn-submit:hover{
    background-color:white;
    color: black;
    transition: 0.5s;
    /* border: 2px solid black; */
}

/* Media Queries */
@media(max-width:750px){
    .sub-input{
        
        width: 20rem;           
    
    }
    .btn-submit{
        width: 20rem;
    }
}
