.love{
    /* border: 2px solid red; */
    padding: 5rem 0;
    background-color: #EDEDED;
}
.love-container{
    /* border: 2px solid blue; */
    display: flex;
    gap: 2rem;
    /* flex-wrap: wrap; */
}
.love-image01{
    
    flex: 1 1 60rem;
    
}
.love-image01 img{
    
    width: 100%;
    height: 100%;
    max-width: 100%;
    
    object-fit: cover;
    object-position: center;

}

.love-items{
    /* border: 2px solid green; */
    flex: 1 1 60rem;
    width: 60rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}
.love-text02{
    /* border: 2px solid black; */
    padding: 2rem;    
    background: #D9DBDD;
}
.btn-love{
    font-size: 2rem;
    padding: 1rem 1.5rem;
    background-color: #6A4AA1;
    color: white;
    border: none;
    outline: none;    
    cursor: pointer;
    margin-bottom: 3rem;
    
}
.btn-love:hover{
    background-color:#D01E55;    
    transition: 0.5s;
}
.love-text02 h1{
    font-size: 3rem;
    margin-bottom: 2rem;
}
.love-text02 p{
    font-size: 1.8rem;
}
.love-image{
padding: 2rem;
background:#E5E5E5;
}
.love-image img{    
    /* border: 2px solid black; */
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}

@media(max-width: 750px){
    .love-container{
      
        flex-wrap: wrap;
    }
}