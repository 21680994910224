footer{
    height: 12vh;
    background-color: #333333;
    color: white;
    padding: 6rem 0;
}
.footer-content{
    display: grid;
    place-items: center;
}
.footer-content a{
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    color: #AF95D5;
}