.about{
    /* border: 2px solid red; */
    padding: 5rem 0;
}
.about-heading h1{
    font-size: 5rem;
    margin-bottom: 3rem;
}
.about-container{
    /* border: 2px solid blue; */
display: flex;
flex-wrap: wrap;
background-color: #F2F2F2;

}
.about-left{
    /* border: 1px solid red; */
    flex: 1 1 100rem;
    padding: 3rem;
    line-height: 4rem;
}
.about-right{
    flex: 1 1 20rem;
    display: grid;
    place-items: center;
    background-color: #6A4AA1;
    color: white;
}

.about-left p{
    font-size: 2rem;
}
.about-left p:nth-child(3){
    font-weight: bold;
}
.so-icons{
    font-size: 4rem;
    display: flex;
    /* border: 2px solid green; */
    gap: 2rem;
}