.team{
    /* border: 2px solid red; */
    padding-top: 5rem;
    padding-bottom: 10rem;
    background-color: #EDEDED;
}
.team-heading{
    text-align: center;
    /* border: 2px solid blue; */
    width: 50%;
    margin-bottom: 5rem;
}
.team-heading h1{
    font-size: 5rem;
    margin-bottom: 2rem;    
}
.team-heading p{
    font-size: 2rem;
    line-height: 4rem;
}
.team-container{
    /* border: 2px solid blue; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.team-items{
    /* border: 2px solid green; */
    background-color: black;
    color: white;
    text-align: center;
}

.team-image{
width: 35rem;
height: 40rem;
}

.team-image img{
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
.team-items h1{
    margin-top: 2rem;
    font-size: 3rem;
}
.team-items p{
    margin: 2rem 0;
    font-size: 1.5rem;
}
.team-items i{
    font-size: 3rem;
    letter-spacing: 1.5rem;
    color: #7F51D6;
    margin-bottom: 2rem;
    cursor: pointer;
}
