.system{
    /* border: 2px solid red; */
    padding-top: 30rem;
    padding-bottom: 30rem;
    position: relative;
    background-color: black;
    
    
}
.system-bar{
  
    position: absolute;
    width: 20%;
    height: 80%;
    top: 10%;
    left: 29%;
    background-color: #7F51D6;
    
}
.system-container{
    
    min-height: 100vh;
}
.system-map{
    /* border: 2px solid black; */
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
}
.system-items{
    /* border: 2px solid green; */
    flex: 1 1 38rem;
    width: 38rem;
    /* max-width: 38rem; */
    height: 40rem;
    cursor: pointer;
    z-index: 1;

}

.system-items img{
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
}
@media(max-width: 1400px){
    .system-bar{
  
    left: 25%;       
        
    }
}

@media(max-width: 1300px){
    .system-bar{
  
    left: 40%;       
        
    }
}
@media(max-width: 850px){
    .system-bar{
  
   
       
        width: 50%;
        height: 90%;
        top: 5%;
        left: 25%;
       
        
    }
}

