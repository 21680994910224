*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'PT Sans', sans-serif;
}
html{
  font-size: 62.5%;
}
.container{
  max-width: 1200px;
  width: 90%;
  margin: auto;
}